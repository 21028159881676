import React, { useState } from 'react';
import { Link } from 'react-router-dom';

//Images
import GLOGO from '../../images/newgenlabs-logo.png';
import GLOGOMOBILE from '../../images/newgenlabs-G-logo.png';
import HAMBURGER from '../../images/hamburger.png';

//Styles
import './Navbar.scss';

const Navbar = ({ logout, loggedIn, user, balance, windowSize }) => {

    const [menuVisible, setMenuVisible] = useState(false);

    const updateSetMenuVisible = () => {
        if (menuVisible) {
            setMenuVisible(false);
        } else {
            setMenuVisible(true);
        }
    }

    const checkMenuVisible = () => {
        if (menuVisible) {
            return "flex";
        } else {
            return "none";
        }
    }

    const menuAnimations = () => {
        if (menuVisible) {
            return "0.25s ease-out 1 fadein";
        } else {
            return "none";
        }
    }

    let menuStyle = {
        display: checkMenuVisible(),
        animation: menuAnimations(),
    };

    return (
        <>
            <div className="navbar">
                <div className="navbar-left">
                    <Link to="/"><img className="navbar-logo" src={GLOGO} alt="NEWGEN LABS logo" /></Link>
                    <Link to="/"><img className="navbar-logo-mobile" src={GLOGOMOBILE} alt="NEWGEN LABS logo" /></Link>
                </div>
                <div className="navbar-right">

                    <div className="ctaButton">
                        <div className="inside">
                        </div>
                        <a
                            className="ctaText discordButton"
                            target="_blank"
                            rel="noreferrer"
                            href="https://newgenlabs.notion.site/Esports-Ascendance-Capsule-067dad6032c6499e83ce4a0164cbdd67"
                        >
                            <span>Drop Details</span>
                        </a>
                    </div>

                    <div className="navigationButtonContainer nav-pc">
                        <a
                            className="navigationButton"
                            target="_blank"
                            rel="noreferrer"
                            href="https://account.playforge.gg/"
                        >
                            Play
                        </a>
                    </div>

                    <div className="navigationButtonContainer nav-pc">
                        <a
                            className="navigationButton"
                            target="_blank"
                            rel="noreferrer"
                            href="https://discord.gg/nftsgen"
                        >
                            Discord
                        </a>
                    </div>

                    <div className="hamburger" onClick={() => updateSetMenuVisible(!menuVisible)}>
                        <img src={HAMBURGER} alt="menu" />
                    </div>
                    {loggedIn &&
                        <div className="navbar-right-account">
                            <div className="navbar-right-account-info">
                                {loggedIn && <p>Wallet ID: {user}</p>}
                                {loggedIn && <p>Balance: {balance && balance}</p>}
                            </div>
                            {loggedIn && (
                                <div className="navigationButtonContainer nav-pc">
                                    <Link to="/inventory" className="navigationButton">Inventory</Link>
                                </div>
                            )}
                            {loggedIn && (
                                <div className="navigationButtonContainer" onClick={() => logout()}>
                                    <p className="navigationButton">Disconnect</p>
                                </div>)}
                        </div>
                    }

                </div>
            </div>

            <div className="hamburger-menu" style={menuStyle}>
                <div className="background"></div>
                <div className="menuContent">
                    <div className="topSegment">
                        <div className="account">
                            {loggedIn &&
                                <div className="navbar-right-account">
                                    <div className="navbar-right-account-info">
                                        {loggedIn && <p>Wallet ID: <span>{user}</span></p>}
                                        {loggedIn && <p>Balance: <span>{balance && balance}</span></p>}
                                    </div>
                                    {loggedIn && (
                                        <div id="disconnectContainer" className="navigationButtonContainer" onClick={() => logout()}>
                                            <p id="disconnectButton" className="navigationButton">Disconnect</p>
                                        </div>)}
                                </div>
                            }
                        </div>
                        <div className="menuSegment">
                            <a
                                className="navigationButton"
                                target="_blank"
                                rel="noreferrer"
                                href="https://account.playforge.gg/"
                            >
                                Play
                            </a>
                        </div>
                        <div className="menuSegment">
                            <Link to="/inventory" className="navigationButton">Inventory</Link>
                        </div>
                        <div className="menuSegment lastSegment">
                            <a
                                className="navigationButton"
                                target="_blank"
                                rel="noreferrer"
                                href="https://discord.gg/nftsgen"
                            >
                                Discord
                            </a>
                        </div>
                    </div>

                    <div className="bottomSegment">
                        <div id="closeMenu" className="menuSegment lastSegment" onClick={() => setMenuVisible(!menuVisible)}>
                            <p>Close</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar;