import React from 'react';

import '../styles/Inventory.scss';

const Inventory = () => {
    return (
        <div className="Inventory">
            <h1>Inventory</h1>
            <p>This is a placeholder for the new inventory page.</p>
        </div>
    );
};

export default Inventory;