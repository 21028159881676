import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
import { JsonRpc } from 'eosjs';
import { Wax } from '@eosdacio/ual-wax';
import { Anchor } from 'ual-anchor';

import './styles/index.scss';
import App from './App';

const appName = 'NEWGEN LABS - DROPS';
const chainId = process.env.REACT_APP_CHAIN_ID;
const protocol = process.env.REACT_APP_RPC_PROTOCOL || 'https';
const host = process.env.REACT_APP_RPC_HOST || 'wax.greymass.com';
const port = process.env.REACT_APP_RPC_PORT || 443;

const chain = {
  chainId,
  rpcEndpoints: [
    {
      protocol,
      host,
      port
    }
  ]
};

const endpoint = `${protocol}://${host}:${port}`;
const rpc = new JsonRpc(endpoint);

const wax = new Wax([chain], { appName });
const anchor = new Anchor([chain], { appName });

const DropsUALConsumer = withUAL(App);

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <UALProvider chains={[chain]} authenticators={[wax, anchor]} appName={appName}>
        <DropsUALConsumer rpc={rpc} />
      </UALProvider>
    </BrowserRouter>
  </React.StrictMode>
);
