import React from 'react';

import GlitchStyle from './ConnectGlitchButton.module.scss';

const ConnectGlitchButton = ({label, cb}) => {
    return (
        <button className={GlitchStyle.connectGlitchBtn} onClick={() => cb()}>{label}</button>
    );
};

export default ConnectGlitchButton;