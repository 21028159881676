import React from 'react';

import ConnectGlitchButton from '../components/SpecialButtons/ConnectGlitchButton';
import MintGlitchButton from '../components/SpecialButtons/MintGlitchButton';

import '../styles/Drop.scss';

const Drop = ({ loggedIn, login, windowSize }) => {

    const dropBackgroundVideo = '/capsule-big.mp4';
    const dropBackgroundImage = '/mobile-background.png';

    const renderDropBackground = (video, image) => {
        return(
            <div>
                <img src={image} alt="drop background capsule" id="Drop-image-background" />
                {windowSize.width > 720 && (
                    <video key={video} autoPlay muted loop id="Drop-video-background">
                        <source src={video} type="video/mp4" />
                    </video>
                )}
            </div> 
        )
        
    };

    const renderDrop = () => {
        if (loggedIn) {
            return (
                <div className="drops-container">
                    <MintGlitchButton label="MINT" cb={() => alert('Patience buddy.. no minting yet..')} />
                </div>
            );
        } else {
            return (
                <div className="drops-container">
                    <p>PLEASE CONNECT YOUR WALLET FOR ACCESS</p>
                    <ConnectGlitchButton label="CONNECT" cb={() => login()} />
                </div>
            );
        }
    };

    return (
        <div className="Drop">
            {renderDropBackground(dropBackgroundVideo, dropBackgroundImage)}
            <div className="Drop-content">
                {renderDrop()}
            </div>
        </div>
    );
};

export default Drop;